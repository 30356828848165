@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shantell+Sans&display=swap");
* {
  padding: 0;
  margin: 0%;
  box-sizing: border-box;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}
.shantel {
  font-family: "Shantell Sans", cursive;
}
.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #ffffff;
}
.App::-webkit-scrollbar {
  display: none;
}
:root {
  --mainColor: #f44336;
}
body {
  font-family: "Poppins", sans-serif;
  background: #f8fafc;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}
button {
  cursor: pointer;
  background: #a7c957;
  color: white;
}
.booking-lottie {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: start;
}
.booking-modal {
  height: 100%;
  position: absolute;
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.booking-lottie .booking-content h2 {
  color: black;
}

.booking-lottie .booking-content h4 {
  font-size: 0.9rem;
  font-weight: 100;
  max-width: 500px;
  margin: 1rem 0;
  line-height: 22px;
}
.booking-lottie .booking-content {
  margin: 0 1rem;
}
.booking-lottie .booking-content button {
  width: 200px;
  height: 2rem;
  font-weight: 700;
  background: #e3c148;
  color: white;
  border: none;
  border-radius: 5px;
}
.booking-lottie .booking-content a {
  color: white;
}

.nya-bosse-root{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.bosse-helt-ny{
  max-width: 500px;
}
@media screen and (max-width: 800px) {
  .booking-lottie {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2rem 0;
    position: relative;
  }
  .booking-lottie .booking-content button {
    position: absolute;
    bottom: -30px;
    left: 21%;
  }
  .booking-lottie {
    margin-bottom: 5rem;
  }
}
.logo {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  font-size: 0.9rem;
}

.snabb-kollen-form {
  display: flex;
  flex-direction: column;
}
.Snabbkollen {
  height: 100%;
  width: 100%;
}
.links-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.partners {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 2rem 0;
  background: rgb(248, 247, 247);
  padding: 1.5rem;
}
.partners p {
  max-width: 340px;
}
.partners section {
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  width: 440px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  background: white;
}
.ddddd {
  display: none;
}


.senaste-mainen{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 800px) {
  .partners {
    flex-direction: column;
  }
  .links-footer-container {
    flex-direction: column;
    align-items: center;
  }
  .senaste-mainen{
    flex-direction: column;
    padding: 1rem;
  }
  .senaste-bild{
    margin-top: 1rem;
  }
}
@media screen and (min-width: 800px) {
  .links-footer-container {
    font-size: 0.9rem;
  }
  .footer-links {
    font-size: 0.8rem;
  }
  .ddddd {
    display: block;
  }
  .main-comp-divider-comp {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 3rem;
  }
  .faq-root {
    max-width: 1150px;
  }
 
}
.besok-img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.office p {
  font-size: 0.7rem;
}
.office div {
  margin: 0.5rem 0.5rem;
}

#erbjuder-cont h4 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: white;
}
#erbjuder-cont p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.45;
  color: white;
  max-width: 536px;
  line-height: 24px;
}
#erbjuder-cont div {
  margin-bottom: 1rem;
}
.stepthree-input {
  width: 0;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 5px;
  background: transparent;
  color: black;
  border: 1px solid black;
  font-size: 16px;
  transition: all 0.3s linear;
  height: 0;
}
.height {
  height: 2.6rem;
  width: 205px;
}
.atv-btn {
  width: 12rem;
  height: 2.2rem;
  border: none;
  background: #bbe3f7;
  margin-top: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.85rem;
  color: black;
}
.toc-root {
  position: absolute;
  bottom: 2%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.toc-root h3 {
  font-weight: 100;
  font-size: 1rem;
  letter-spacing: 0.5px;
  margin-right: 0.3rem;
}
.toc-links:hover {
  border-bottom: 1px solid white;
}
